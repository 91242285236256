







































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
// import { defineComponent } from "vue";

import {
  BCard,
  BFormFile,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
} from "bootstrap-vue";
import {
  AppEditor,
  AppInput,
  AppFilePreview,
  AppForm,
  AppSelect,
  AppButton,
} from "@/components/form/index";
// import Seo from "./Seo.vue";
import GalleryModal from "@/components/UI/GalleryModal.vue";
import { toFormData } from "@/utils/index";
import SeoWrapper from "@/components/Seo/SeoWrapper.vue";

// interface Form {
//   title_ar: string;
//   slug_ar: string;
//   content_ar: string;
//   image: string;
//   is_featured: boolean;
//   in_hotbar: boolean;
//   category: string;
//   tags: object;
//   metaTitle: string;
//   metaDescription: string;
//   metaKeywords: object;
//   metaFocusKeyphrase: string;
//   facebookTitle: string;
//   facebookDescription: string;
//   facebookImage: string;
//   twitterTitle: string;
//   twitterDescription: string;
//   twitterImage: string;
// }

@Component({
  name: "AddNewnews",
  components: {
    AppInput,
    GalleryModal,
    AppEditor,
    BCard,
    BFormGroup,
    BFormFile,
    AppFilePreview,
    AppForm,
    BFormCheckbox,
    AppSelect,
    BRow,
    BCol,
    AppButton,
    SeoWrapper,
  },
  data() {
    return {
      successMessage: null,
      id: "add-new-news",
      form: {
        title_ar: null,
        slug_ar: null,
        content_ar: null,
        image: null,
        is_featured: "0",
        in_hotbar: "0",
        category: null,
        brief_content_ar: null,
        tags: [],
        seo: {},
        // metaTitle: null,
        // metaDescription: null,
        // metaKeywords: null,
        // metaFocusKeyphrase: null,
        // facebookTitle: null,
        // facebookDescription: null,
        // facebookImage: null,
        // twitterTitle: null,
        // twitterDescription: null,
        // twitterImage: null,
      },
      seo_meta: {
        //
      },
    };
  },
  computed: {
    isLoading() {
      return (
        (this as any).$store.state.news.isLoading.creating ||
        (this as any).$store.state.news.isLoading.updating
      );
    },
    errorsBag() {
      return (this as any).$store.state.news.errors;
    },
    categories() {
      return (this as any).$store.state.news_category.data || [];
    },
    tags() {
      return (this as any).$store.state.news_tag.data || [];
    },
    singleData() {
      return (this as any).$store.state.news.singleData;
    },
    doesErrorsExist() {
      return !!(
        // ((this as any).errorsBag &&
        //   Object.keys((this as any).errorsBag) &&
        //   Object.keys((this as any).errorsBag).length) ||
        (
          (this as any).errors &&
          (this as any).errors.items &&
          (this as any).errors.items.length
        )
      );
    },
  },
  created() {
    this.$store.dispatch("news/resetModule");
    this.$store.dispatch("news_category/fetchAllData");
    this.$store.dispatch("news_tag/fetchAllData");
    this.$route.query &&
      this.$route.query.uuid &&
      this.$store.dispatch("news/fetchSingleItem", this.$route.query.uuid);
  },
  watch: {
    singleData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.form = {
            ...newVal,
          };
          this.seo_meta = newVal?.seo || {};
        }
      },
      deep: true,
    },
  },
  methods: {
    changeSeoHandler(key, value) {
      if (!key) return;
      (this as any).form["seo"][key] = value;
    },
    selectImageHandler(image) {
      (this as any).form.image = image;
    },
    cancelHandler() {
      (this as any).$emit("cancel");
      (this as any).$router.push("/news");
    },
    openGallaryModal() {
      (this as any).$bvModal.show("gallary-modal");
    },
    submitAndAddNewHandler() {
      // (this as any).$emit("submit-and-add-new");
      (this as any).handleSubmit(false);
    },
    removeImageHandler() {
      (this as any).form.image = null;
    },
    handleSubmit(goToListing = true) {
      const actionName = (this as any).singleData ? "updateData" : "createData";

      (this as any).$store
        .dispatch(`news/${actionName}`, toFormData((this as any).form))
        .then(() => {
          (this as any).hideForm();
          (this as any).successMessage = (this as any).$t("successfully_saved");
          goToListing && (this as any).$router.push({ name: "news" });
        });
    },
    hideForm() {
      (this as any).$bvModal.hide((this as any).id);
      (this as any).$store.dispatch("news/resetModule");
      (this as any).form = {
        title_ar: null,
        slug_ar: null,
        content_ar: null,
        image: null,
        is_featured: false,
        in_hotbar: false,
        category: null,
        tags: [],
        metaTitle: null,
        metaDescription: null,
        metaKeywords: null,
        metaFocusKeyphrase: null,
        facebookTitle: null,
        facebookDescription: null,
        facebookImage: null,
        twitterTitle: null,
        twitterDescription: null,
        twitterImage: null,
      };
      (this as any).$emit("hide");
    },
  },
})
export default class AddNewnews extends Vue {
  // @Prop({ type: [String, Number], required: true }) id!: string | number;
  @Prop({ type: Object, required: false }) initialData: object;
}
